import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';
import { ObjectUtilModule } from '../object-util/object-util.module';
import { ChoiceRequired } from './choice-validator';
import { ChoicesComponent } from './choices.component';
import { PopoverPage } from './popover.page';

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    ObjectUtilModule,
  ],
  exports: [
    ChoicesComponent,
    PopoverPage,
    ChoiceRequired
  ],
  declarations: [ChoicesComponent, PopoverPage, ChoiceRequired]
})
export class ChoicesModule {}
