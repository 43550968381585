import { Pipe, PipeTransform } from '@angular/core';
import { Choice } from '../choices/choice';

@Pipe({ name: 'getName' })
export class GetNamePipe implements PipeTransform {
  transform(id: string, col: Choice[], defaultIfEmpty: string = ""): string {
    let retVal: string = "";

    if (col) {
      let obj = col.find(obj => {
        return obj.id == id;
      });
      if (obj) {
        retVal = obj.name;
        if (obj.parentNames) {
          retVal = retVal + ' (' + obj.parentNames + ')';
        }
      }
    }

    if (retVal == "" || retVal == undefined) {
      retVal = defaultIfEmpty;
    }
    return retVal;
  }
}