import { Component, OnDestroy, OnInit, Optional } from "@angular/core";
import { MenuController } from "@ionic/angular";
import { Subscription } from "rxjs";
import { Page } from "../page";
import { Nav } from "../services/nav";
import { PageAwareService } from "../services/page-aware.service";
import { SessionService } from "../services/session.service";

import { AppStateService } from "src/app/service/appState.service";

@Component({
  template: "",
})
export abstract class PageAwareComponent implements OnInit, OnDestroy {
  public showNav: boolean;
  public navIndex: number = -1;
  public appNavs: Nav[] = [];
  public userAccess: number = -1;

  public isMobile: boolean;
  public userIsClientAdmin: boolean;
  private page: Page;

  private deviceChangedSubscription: Subscription;
  private pageChangedSubscription: Subscription;

  constructor(
    protected pageAwareService: PageAwareService,
    protected sessionService: SessionService,
    protected menuController: MenuController
  ) {
    this.appNavs = this.pageAwareService.appNavs;
  }

  ngOnInit() {
    this.deviceChangedSubscription =
      this.pageAwareService.deviceChangedSubject.subscribe((isMobile) => {
        this.isMobile = isMobile;

        this.toggleNavigation();
      });

    this.pageChangedSubscription =
      this.pageAwareService.pageChangedSubject.subscribe((page) => {
        this.page = page;

        this.toggleNavigation();
      });
  }

  ngOnDestroy() {
    if (this.deviceChangedSubscription) {
      this.deviceChangedSubscription.unsubscribe();
    }
    if (this.pageChangedSubscription) {
      this.pageChangedSubscription.unsubscribe();
    }
  }

  private toggleNavigation() {
    if (this.page) {
      if (this.page.hasNav) {
        if (this.isMobile) {
          this.menuController.enable(true);
          this.showNav = false;
        } else {
          this.menuController.enable(false);
          this.showNav = true;
        }
      } else {
        this.menuController.enable(false);
        this.showNav = false;
      }
      this.menuController.getMenus();
      this.navIndex = this.page.navIndex.valueOf();

      this.getActionAlerts();
    }

    this.sessionService.get("access").then((val) => {
      this.userAccess = Number(val);
    });

    this.sessionService.get("administrator").then((val) => {
      this.userIsClientAdmin = val == "Y";
    });
  }

  private getActionAlerts() {
    this.sessionService.get("assigned_actions").then((val) => {
      const ACTION_NAV_INDEX = 5;
      this.appNavs[ACTION_NAV_INDEX].no_of_alerts = val;
    });
  }
}
