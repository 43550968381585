import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { CommonModule, DatePipe } from "@angular/common";
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from "@angular/common/http";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";

import { PDFGenerator } from "@ionic-native/pdf-generator/ngx";
import { File } from "@ionic-native/file/ngx";
import { FileOpener } from "@ionic-native/file-opener/ngx";

import { IonicStorageModule } from "@ionic/storage";
import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";

import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgxChartsModule } from "@swimlane/ngx-charts";
import { NgxGaugeModule } from "ngx-gauge";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { DatePicker } from "@ionic-native/date-picker/ngx";

import { Camera } from "@ionic-native/camera/ngx";
import { WebView } from "@ionic-native/ionic-webview/ngx";
import { FilePath } from "@ionic-native/file-path/ngx";
import { ChoicesModule } from "./common/components/choices/choices.module";
import { RestErrorInterceptor } from "./common/services/rest-error.interceptor";
import { CustomRouteReuseStrategy } from "./custom.route.reuse.strategy";

import { HTTP } from "@ionic-native/http/ngx";
import { GetNamePipe } from "./common/components/object-util/get-name.pipe";
import { WINDOW_PROVIDERS } from "./common/window.provider";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { AppStateService } from "src/app/service/appState.service";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}
@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    CommonModule,
    NgxChartsModule,
    NgxGaugeModule,
    BrowserAnimationsModule,
    IonicModule.forRoot(),
    IonicStorageModule.forRoot(),
    ChoicesModule,
    AppRoutingModule,
    HttpClientModule,
    //
    // TranslateModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    AppStateService,
    DatePicker,
    DatePipe,
    GetNamePipe,
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: RestErrorInterceptor, multi: true },
    PDFGenerator,
    File,
    FileOpener,
    Camera,
    File,
    WebView,
    FilePath,
    HTTP,
    WINDOW_PROVIDERS,
    {
      provide: RouteReuseStrategy,
      useClass: CustomRouteReuseStrategy,
    },
    AppStateService,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
