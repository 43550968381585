import { Component, OnInit } from '@angular/core';
import { NavParams, PopoverController } from '@ionic/angular';
import { Choice } from './choice';

@Component({
  selector: 'app-popover',
  templateUrl: './popover.page.html',
  styleUrls: ['./popover.page.scss']
})
export class PopoverPage implements OnInit {

  choice: Choice;
  choices: Choice[];
  selectedParent: string = '';

  constructor(private popoverController: PopoverController, private navParams:NavParams) { 
    this.choice = this.navParams.get('choice');
    this.choices = this.navParams.get('choices');
    this.selectedParent = this.navParams.get('selectedParent');
  }
  
  ngOnInit() {}

  pickChoice(choice: Choice) {
    this.popoverController.dismiss(choice);
  }

}
