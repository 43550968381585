import { ChangeDetectorRef, Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AppStateService {
  constructor(private translate: TranslateService) { }
  public defaultLanguage: string = "en";
  public prevlanguage: string = "en";
  private languageStorageKey = "appLanguage";
  private prevLanguageStorageKey = "appPrevLanguage";
  public langguageChangeENUM = "CHANGE";

  //for checklist routing
  _paramsChecklistName: string = ''
  _paramsSortkey: string = ''

  notify = new Subject();
  $notify = this.notify.asObservable();

  initializeLanguage() {
    this.defaultLanguage = this.getSavedLanguage();
    if (this.defaultLanguage === "") {
      this.defaultLanguage = "en";
      localStorage.setItem(this.languageStorageKey, this.defaultLanguage);
      localStorage.setItem(this.prevLanguageStorageKey, this.prevlanguage);
    }
    this.translate.setDefaultLang(this.defaultLanguage);
    this.translate.use(this.defaultLanguage);
  }

  setTranslateLanguage(type: string, prevLang: string, language: string) {
    if (type === this.langguageChangeENUM)
      this.prevlanguage = prevLang;

    this.defaultLanguage = language;
    this.translate.setDefaultLang(this.defaultLanguage);
    this.translate.use(this.defaultLanguage);

    localStorage.setItem(this.languageStorageKey, this.defaultLanguage);
    localStorage.setItem(this.prevLanguageStorageKey, this.prevlanguage);

    console.log('app state service : type nad language ', type, this.prevlanguage, this.defaultLanguage)
    if (type == this.langguageChangeENUM) this.notify.next("languageChanged");
  }

  getSavedLanguage(): string {
    localStorage.setItem(this.prevLanguageStorageKey, this.prevlanguage);

    return (
      localStorage.getItem(this.languageStorageKey) || this.defaultLanguage
    );
  }

  getTranslatedTitle(title: string) {
    const translatedKey = title;
    const test = this.translate.instant(translatedKey);
    return test;
  }
}
