import { Pipe, PipeTransform } from '@angular/core';
import { User } from '../../services/users/user';

@Pipe({name: 'getUseryEmail'})
export class GetUserByEmailPipe implements PipeTransform {
  transform(email: string, users: User[]): string {
    let retVal: string = "script";
    if(users) {
      let user = users.find(obj => {
        return obj.email == email;
      });
      if(user) {
        retVal = user.last_name.toUpperCase()  + ", " + user.first_name.substr(0, 1).toUpperCase() + user.first_name.substr(1).toLowerCase();
      }
    }
    return retVal;
  }
}