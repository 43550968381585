import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { Observable, BehaviorSubject } from 'rxjs';

import { FileResponse } from './file-response';
import { RestService } from '../rest.service';
import { SessionService } from '../session.service';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  fileSubject = new BehaviorSubject(false);

  constructor(private restService: RestService, private sessionService: SessionService) { }

  upload(type: string, formData: FormData): Observable<FileResponse> {
    return this.restService.post(`/files/${type}`, formData).pipe(
      tap(async (res: FileResponse) => {
        return res;
      })
    );
  }

  download(type: string, group: string, subgroup: string, fileId: string): Observable<ArrayBuffer> {
    return this.restService.stream(`/files/${type}/${group}/${subgroup}/${fileId}`).pipe(
      tap(async (res: ArrayBuffer) => {
        return res;
      })
    );
  }

  getAsset(url: string): Observable<ArrayBuffer> {
    return this.restService.stream(`${url}`).pipe(
      tap(async (res: ArrayBuffer) => {
        return res;
      })
    );
  }

  delete(type: string, group: string, subgroup: string, fileId: string): Observable<FileResponse> {
    return this.restService.delete(`/files/${type}/${group}/${subgroup}/${fileId}`).pipe(
      tap(async (res: FileResponse) => {
        return res;
      })
    );
  }

  list(type: string, group: string, subgroup: string): Observable<string[]> {
    return this.restService.get(`/files/${type}/${group}/${subgroup}`).pipe(
      tap(async (res: string[]) => {
        return res;
      })
    );
  }

  slice(base64: string): Observable<string[]> {
    return this.restService.post(`/extract/slice`, {base64: base64}).pipe(
      tap(async (res: any[]) => {
        return res;
      })
    );
  }

  generatePDF(reportName: string, urlPath: string, filters: any) {
    return this.restService.post(`/extract/generate`, {reportName: reportName, urlPath: urlPath, filters: filters}).pipe(
      tap(async (res: any) => {
        return res;
      })
    );
  }

  saveToFileSystem(blob, filename) {
    var link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.setAttribute("download", filename);
    link.click(); 
  }

  base64toBlob(base64Data, contentType='', sliceSize=512) {
    const byteCharacters = atob(base64Data);
    const byteArrays = [];
  
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
  
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
  
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
  
    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
  }

}
