import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'countElements',
  pure: false
})
export class CountElementsPipe implements PipeTransform {
  transform(element_compliance: {}, field: string, value: any): number {
    let retVal = 0;    
    if(element_compliance) {
      Object.keys(element_compliance).forEach(key => {
        if(element_compliance[key][field] == value) {
          retVal++;
        }
      });
    }
    
    return retVal;
  }
}