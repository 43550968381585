import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { Observable, BehaviorSubject } from 'rxjs';

import { User } from '../users/user';
import { AuthResponse } from './auth-response';
import { RestService } from '../rest.service';
import { SessionService } from '../session.service';
import { PageAccess } from '../../page.access';
import { Auth } from './auth';
import { AdminAuth } from './admin-auth';
import { ModulesService } from '../modules/modules.service';
import { SequentialExecutor } from '../../components/sequential-executor';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  authSubject = new BehaviorSubject(false);

  constructor(private restService: RestService, 
    private sessionService: SessionService,
    private modulesService: ModulesService) { }

  login(auth: Auth, onSuccess: (resp: AuthResponse) => void, onError?: (reason?: any) => void): void {
    this.restService.post(`/auth/login`, auth)
      .subscribe((res: AuthResponse) => {
        new SequentialExecutor().chain((resolve) => {
          this.setSession(res, resolve);
        })
        .fail((error) => {
          if(onError) {
            onError(error);
          }
        })
        .success(()=> {
          onSuccess(res);
        })
        .execute();
      }
    );
  }

  loginAsAdmin(adminAuth: AdminAuth, onSuccess: (resp: AuthResponse) => void, onError: (reason?: any) => void): void {
    this.restService.post(`/admin/login-as-client`, adminAuth).subscribe(
      (res: AuthResponse) => {
        new SequentialExecutor().chain((resolve) => {
          this.setSession(res, resolve);
        })
        .fail((error) => {
          if(onError) {
            onError(error);
          }
        })
        .success(()=> {
          onSuccess(res);
        })
        .execute();
      }
    );
  }

  isLoggedIn(): Observable<{status: string}> {
    return this.restService.get(`/auth-check`).pipe(
      tap(async (res: {status: string}) => {
        return res;
      })
    );
  }

  logout(): Observable<AuthResponse> {
    return this.restService.post(`/auth/logout`, {}).pipe(
      tap(async (res: AuthResponse) => {
        this.clearSession();
        this.authSubject.next(false);
        return res;
      })
    );
  }

  startSession(sessionId) {
    this.sessionSet("sessionId", sessionId);
  }

  private setSession(authResponse: AuthResponse, resolve) {
    this.startSession(authResponse['sessionId']);
    this.sessionSet("user_id", authResponse['user_id']);
    this.sessionSet("client_id", authResponse['client_id']);
    this.sessionSet("email", authResponse['email']);
    this.sessionSet("assigned_actions", authResponse['assigned_actions']);
    //this.sessionSet("accessToken", res['accessToken']); //TODO remove this once ssl cert is available
    this.sessionSet("first_name", authResponse['first_name']);
    this.sessionSet("last_name", authResponse['last_name']);

    this.sessionSet("reporting", authResponse['reporting']);
    this.sessionSet("data_entry", authResponse['data_entry']);

    this.sessionSet("password_changed", authResponse['password_changed'])

    if(authResponse['module'] == "ADMIN") {
      this.sessionSet("access", PageAccess.ADMIN.toString());

      resolve(true);
      this.authSubject.next(true);
    } else {
      this.sessionSet("module_access", authResponse['module_access']);
      this.sessionSet("administrator", authResponse['administrator']);
      this.sessionSet("access", PageAccess.CLIENT.toString());

      let userHasActionsAccess = authResponse['module_access'].indexOf('AM') > -1;
      this.sessionSet("user_has_actions_access", userHasActionsAccess ? 'Y' : 'N');

      this.modulesService.getModules().subscribe(
        (response) => {
          let actionsModule = response.modules.find(module => {
            return module.id == 'AM';
          });
          // 
          this.sessionSet("actions_module_activated", actionsModule.is_activated && actionsModule.is_activatable ? 'Y' : 'N');

          resolve(true);
          this.authSubject.next(true);
        }
      );
    }

  }

  private clearSession() {
    this.sessionSet("access","-1");
    this.sessionSet("administrator","N");
    this.sessionSet("assigned_actions","0");
    this.sessionService.clear();
  }

  private sessionSet(key:string, value: string) {
    this.sessionService.set(key, value);
  }

  private sessionGet(key:string):Promise<any> {
    return this.sessionService.get(key);
  }

  getAdmin(): Observable<AuthResponse> {
    return this.restService.get(`/users/admin`).pipe(
      tap(async (res: AuthResponse) => {
        return res;
      })
    );
  }

  retrievePassword(user: User): Observable<AuthResponse> {
    return this.restService.post(`/auth/retrieve-password`, user).pipe(
      tap(async (res: AuthResponse) => {
        if (res.login) {
          this.authSubject.next(true);
        }
        return res;
      })
    );
  }

  changePassword(auth: Auth): Observable<AuthResponse> {
    return this.restService.put(`/credentials`, auth).pipe(
      tap(async (res: AuthResponse) => {
        this.authSubject.next(true);
        return res;
      })
    );
  }

  authorize(token: string, onSuccess: (resp: AuthResponse) => void, onError?: (reason?: any) => void): void {
    this.restService.post(`/auth/authorize`, {}, {}, {'Authorization': token})
      .subscribe((res: AuthResponse) => {
        new SequentialExecutor().chain((resolve) => {
          this.setSession(res, resolve);
        })
        .fail((error) => {
          if(onError) {
            onError(error);
          }
        })
        .success(()=> {
          onSuccess(res);
        })
        .execute();
      }
    );
  }

}
