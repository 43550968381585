import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'isValidImage'})
export class IsValidImagePipe implements PipeTransform {
  transform(base64: string): boolean {
    let retVal = false;

    if(base64 != undefined) {
      let type = base64.split(';')[0];

      let accepted = ['data:image/jpg', 'data:image/png'];

      if(accepted.indexOf(type) > -1) {
        retVal = true;
      }
    }
    return retVal;
  }
}