import { EventEmitter, Injectable, OnInit, Output } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class SequentialExecutor implements OnInit {
  
  private executionList: ((resolve: (value: boolean) => void, reject: (reason?: any) => void) => void)[];
  private onFail : (error?: any) => void;
  private onSuccess : () => void;
  private onAlways : () => void;

  ngOnInit() { }

  SequentialExecutor() { }

  chain(executeThis?: (resolve: (value: boolean) => void, reject: (reason?: any) => void) => void): SequentialExecutor {
    let executor = new SequentialExecutor();
    executor.executionList = [];
    if(executeThis) {
      return executor.then(executeThis);
    } else {
      return executor;
    }
  }

  parallel(executeThese: ((resolve: (value: boolean) => void, reject: (reason?: any) => void) => void)[]): SequentialExecutor {
    return this.then((res, rej) => {
      this.executeParallel(executeThese, 
        () => {
          res(true);
        },
        () => {
          rej("Parallel execution failed");
        }
      )
    });
  }

  then(executeThis: (resolve: (value: boolean) => void, reject: (reason?: any) => void) => void): SequentialExecutor {
    this.executionList.push(executeThis);
    return this;
  }

  fail(fail: (error?: any) => void) {
    this.onFail = fail;
    return this;
  }

  success(success: () => void) {
    this.onSuccess = success;
    return this;
  }

  always(always: () => void) {
    this.onAlways = always;
    return this;
  }
  
  execute() {
    this.executeInternal(this.executionList, this.onSuccess, this.onFail, this.onAlways);
  }

  private async executeInternal (
    executionList: ((resolve: (value: boolean) => void, reject: (reason?: any) => void) => void)[],
    onSuccess ?: () => void,
    onFail ?: (error?: any) => void,
    onAlways ?: () => void
  ) {

    let breakLoop = false;
    for (let index = 0; index < executionList.length; index++) {
      if(breakLoop) {
        break;
      }

      let execution = executionList[index];
      var synCaller = new Promise((resolve, reject) => {
        try {
          //
          execution(resolve, reject); 
        } catch(e) {
          reject(e.message);
        }
      });
  
      await synCaller.then(() => {
        //
        if(index == executionList.length - 1) {
            //
            if(onSuccess != undefined) {
            onSuccess();
          }
          if(onAlways != undefined) {
            onAlways();
          }
        }
      });

      await synCaller.catch((error) => {
        if(onFail != undefined) {
          onFail(error);
        }
        if(onAlways != undefined) {
          onAlways();
        }
        //
        breakLoop = true;
      });
    }
  }

  private executeParallel (
    executionList: ((resolve: (value: boolean) => void, reject: (reason?: any) => void) => void)[],
    onSuccess ?: () => void,
    onFail ?: () => void,
    onAlways ?: () => void
  ) {

    if(executionList.length == 0) {
      onSuccess();
    }

    let finishedCount = 0;
    for (let index = 0; index < executionList.length; index++) {

      let execution = executionList[index];
      var synCaller = new Promise((resolve, reject) => {
        try {
          execution(resolve, reject); 
        } catch(e) {
          reject(e.message);
        }
      });
  
      synCaller.then(() => {
        finishedCount++;
        //
        if(finishedCount == executionList.length) {
          //
          if(onSuccess != undefined) {
            onSuccess();
          }
          if(onAlways != undefined) {
            onAlways();
          }
        }
      }).catch(() => {
        if(onFail != undefined) {
          onFail();
        }
        if(onAlways != undefined) {
          onAlways();
        }
        //
      });
    }
  }
}