import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class RestService {

    BFF_SERVER_ADDRESS: string = environment.bffUrl;

    defaultHeaders = {};

    constructor(private httpClient: HttpClient) { }

    stream(url: string, params?: any, headers = this.defaultHeaders): Observable<Object> {
        return this.httpClient.get(this.getUrl(url), { withCredentials: true, headers: headers, params: params, responseType: 'arraybuffer' }).pipe(catchError(this.handleError));
    }

    get(url: string, params?: any, headers = this.defaultHeaders): Observable<Object> {
        let token = new URLSearchParams(window.location.search).get('token');
        if (token)
            url += url.indexOf('?') != -1 ? ('&token=' + token) : ('?token=' + token);
        return this.httpClient.get(this.getUrl(url), { withCredentials: true, headers: headers, params: params }).pipe(catchError(this.handleError));
    }

    put(url: string, putData: any, params?: any, headers = this.defaultHeaders): Observable<Object> {
        return this.httpClient.put(this.getUrl(url), putData, { headers, withCredentials: true, params: params }).pipe(catchError(this.handleError));
    }

    post(url: string, postData: any, params?: any, headers = this.defaultHeaders): Observable<Object> {
        let token = new URLSearchParams(window.location.search).get('token');
        if (token)
            url += url.indexOf('?') != -1 ? ('&token=' + token) : ('?token=' + token);
        return this.httpClient.post(this.getUrl(url), postData, { headers, withCredentials: true, params: params }).pipe(catchError(this.handleError));
    }

    delete(url: string, params?: any, headers = this.defaultHeaders): Observable<Object> {
        return this.httpClient.delete(this.getUrl(url), { withCredentials: true, headers: headers, params: params }).pipe(catchError(this.handleError));
    }

    handleError(error: HttpErrorResponse) {
        if (error.error instanceof Error) {
            // 
        } else {
            // 
        }
        return throwError(error);
    };

    getUrl(url: string): string {
        if (url.startsWith('/')) {
            url = this.BFF_SERVER_ADDRESS + url;
        }
        return url;
    }
}