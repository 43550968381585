import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'getPDFIndexes'})
export class GetPDFIndexesPipe implements PipeTransform {
  transform(arrayLength: number): {start: number, end: number}[] {
    let retVal: {start: number, end: number}[] = [];

    let startIndex = 3;
    let recordsPerPage = 6;

    if(arrayLength > 0) {
      for(let i=startIndex; i<arrayLength;) {
        if(i==0) {
          retVal.push({
            start: 0,
            end: 3
          });
          i = 3;
        } else {
          retVal.push({
            start: i,
            end: i + recordsPerPage
          });
          i += recordsPerPage;
        }
      }
    }

    return retVal;
  }
}