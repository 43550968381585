import { Component, EventEmitter, forwardRef, Input, OnInit, Output, } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { PopoverController } from '@ionic/angular';
import { Choice } from './choice';
import { PopoverPage } from './popover.page';

@Component({
  selector: 'app-choices',
  templateUrl: './choices.component.html',
  styleUrls: ['./choices.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ChoicesComponent),
      multi: true
    }
  ]  
})
export class ChoicesComponent implements OnInit, ControlValueAccessor {

  propagateChange = (_: any) => {};
  propagateTouched = () => {};

  writeValue(obj: any): void {
    this.selectedId = obj;
  }
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }
  registerOnTouched(fn: any): void { 
    this.propagateTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void { }

  constructor(private popoverController: PopoverController) { }

  @Input()
  choices: Choice[] = [];  

  @Input()
  label: string = "";

  @Input()
  labelClass: string = "";

  @Input()
  placeholder: string = "";

  @Input()
  selectedParent: string = "";

  @Input()
  resetAfterSelect: boolean = false;

  @Input()
  readonly: boolean = false;

  selectedId: string = "";
  choice: Choice;

  ngOnInit() {
    this.getChoice();
  }

  getChoice() {
    this.choice = this.choices.find(choice => choice.id === this.selectedId);
    if(this.choice == undefined) {
      this.choice = {id: '', name: ''};
    }
  }

  @Output()
  choicePicked : EventEmitter<Choice> = new EventEmitter<Choice>();

  async presentChoices(event: any) {
    if(!this.readonly) {
      this.getChoice();
      if(this.choice == undefined) {
        this.choice = {id:'', name: 'None', parentNames: '', parents: ''};
      }
  
      const popover = await this.popoverController.create({
        component: PopoverPage,
        componentProps:{
          choice:this.choice, 
          choices: this.choices,
          selectedParent: this.selectedParent
        },
        event: event,
        cssClass: 'popover-style',
        translucent: true,
      });
  
      await popover.present();
      await popover.onDidDismiss().then((result: any) => {
        if(result.data) {
          this.selectedId = result.data.id;
          this.getChoice();
  
          this.broadcastChoice(event);
        }
      });
    }
  }  

  private broadcastChoice(event: Event) {
    this.propagateTouched();
    this.propagateChange(this.choice);
    this.choicePicked.emit(this.choice);
    event.preventDefault();
    event.stopPropagation();
    if(this.resetAfterSelect) {
      this.choice = undefined;
      this.selectedId = "";
    }
  }

}
