import { Pipe, PipeTransform } from '@angular/core';
import { Category } from '../../services/category-elements/category';

@Pipe({name: 'getCategory'})
export class GetCategoryPipe implements PipeTransform {
  transform(id: string, categories: Category[], defaultIfEmpty: Category = {id: '', name: '', type: '', elements: []}): Category {
    let retVal: any = undefined;
    if(categories) {
      let obj = categories.find(category => {
        return category.id == id;
      });
      retVal = obj;
    }

    if(retVal == undefined) {
      retVal = defaultIfEmpty;
    }

    return retVal;
  }
}