import { Component } from "@angular/core";

import { MenuController, Platform } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { PageAwareComponent } from "./common/components/page-aware.component";
import { SessionService } from "./common/services/session.service";
import { PageAwareService } from "./common/services/page-aware.service";
import { Router } from "@angular/router";
import { Nav } from "./common/services/nav";

import { AppStateService } from "src/app/service/appState.service";

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent extends PageAwareComponent {
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    protected pageAwareService: PageAwareService,
    protected sessionService: SessionService,
    protected menuController: MenuController,
    protected router: Router
  ) {
    super(pageAwareService, sessionService, menuController);
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  navRedirect(nav: Nav) {
    this.router.navigateByUrl(nav.url);
  }
}
