import { Injectable } from '@angular/core';
import { OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Page } from '../../common/page';
import { PageAccess } from '../../common/page.access';
import { Nav } from './nav';

@Injectable({
  providedIn: 'root'
})
export class PageAwareService implements OnInit {

  public appNavs: Nav[] = [
    {
      title: 'Clients',
      url: '/admin/clients',
      access: PageAccess.ADMIN,
      icon: '',
      no_of_alerts: 0
    },
    {
      title: 'Admin',
      url: '/admin/weightings',
      access: PageAccess.ADMIN,
      icon: '',
      no_of_alerts: 0
    },
    {
      title: 'Password',
      url: '/admin/credentials',
      access: PageAccess.ADMIN,
      icon: '',
      no_of_alerts: 0
    },
    {
      title: 'Logout',
      url: '/login',
      access: PageAccess.ADMIN,
      icon: '',
      no_of_alerts: 0
    },
    {
      title: 'Dashboards',
      url: '/client',
      access: PageAccess.CLIENT,
      client_admin: false,
      icon: '',
      no_of_alerts: 0
    },
    {
      title: 'Actions',
      url: '/client/actions',
      access: PageAccess.CLIENT,
      client_admin: false,
      icon: '',
      no_of_alerts: 0
    },
    {
      title: 'Reports',
      url: '/client/reports',
      access: PageAccess.CLIENT,
      client_admin: false,
      icon: '',
      no_of_alerts: 0
    },
    {
      title: 'Data',
      url: '/client/data',
      access: PageAccess.CLIENT,
      client_admin: false,
      icon: '',
      no_of_alerts: 0
    },
    {
      title: 'Users',
      url: '/client/users',
      access: PageAccess.CLIENT,
      client_admin: true,
      icon: '',
      no_of_alerts: 0
    },
    {
      title: 'Admin',
      url: '/client/admin',
      access: PageAccess.CLIENT,
      client_admin: true,
      icon: '',
      no_of_alerts: 0
    },
    {
      title: 'Account',
      url: '/client/account',
      access: PageAccess.CLIENT,
      client_admin: false,
      icon: '',
      no_of_alerts: 0
    },
    {
      title: 'Logout',
      url: '/login',
      access: PageAccess.CLIENT,
      client_admin: false,
      icon: '',
      no_of_alerts: 0
    }
  ];

  deviceChangedSubject = new Subject<boolean>();
  pageChangedSubject = new Subject<Page>();

  private currentNav: Nav;
  private currentPage: Page;
  private userAccess: number;
  private userIsClientAdmin: boolean

  constructor() { }

  ngOnInit() { }

  public deviceChanged(isMobile: boolean) {
    this.deviceChangedSubject.next(isMobile);
  }

  public pageChanged(page: Page) {
    this.currentPage = page;
    if (page.hasNav) {
      this.currentNav = this.appNavs[+page.navIndex];
      this.currentNav.no_of_alerts = 0; //reset alerts
    }
    page.nav = this.currentNav;
    this.pageChangedSubject.next(page);
  }

  public getAppNavs() {
    return this.appNavs;
  }

  public getCurrentPage() {
    return this.currentPage;
  }

  public setUserAccess(userAccess: number) {
    this.userAccess = userAccess;
  }

  public getUserAccess(): number {
    return this.userAccess;
  }

  public setUserClientAdmin(userIsClientAdmin: boolean) {
    this.userIsClientAdmin = userIsClientAdmin;
  }

  public getUserClientAdmin(): boolean {
    return this.userIsClientAdmin;
  }
}
