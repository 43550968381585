import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { Storage } from '@ionic/storage';
import { RestService } from 'src/app/common/services/rest.service';
import { Modules } from './modules';
import { Module } from './module';

@Injectable({
  providedIn: 'root'
})
export class ModulesService {

  constructor(private restService: RestService, private storage: Storage) {}

  getModules(): Observable<Modules> {
    return this.restService.get(`/modules`).pipe(
      tap(async (res: Modules) => {
        res.modules.sort(function (a, b) {
          return a.sort_num - b.sort_num;
        });
        return res;
      })
    );
  }

  getModule(moduleId:number): Observable<Modules> {
    return this.restService.get(`/modules/${moduleId}`).pipe(
      tap(async (res: Modules) => {
          return res;
      })
    );
  }

  updateModule(module: Module): Observable<Module> {
    return this.restService.put(`/modules/${module.id}`, module).pipe(
      tap(async (res: Module) => {
        return res;
      })
    );
  }

}