import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-qr-redirect",
  templateUrl: "./qr-redirect.component.html",
  styleUrls: ["./qr-redirect.component.scss"],
})
export class QrRedirectComponent implements OnInit {
  constructor(
    private router: Router,
  ) {
    let platform = this.checkPlatform();
    
    if(platform == "Android" || platform == "LINUX") {
      window.location.href = "https://play.google.com/store/apps/details?id=com.ga.trendsafe";
    } else if(platform == "IOS") {
      window.location.href = "https://apps.apple.com/us/app/trendsafe/id1616866679";
    } else {
      this.router.navigate(['/login']);
    }
  }

  platforms: string = "";
  currentPlatform: string = "";

  ngOnInit() { }

  checkPlatform() {
    let platform = "";
  
    if (navigator.platform.indexOf("Win") !== -1) platform = "Windows";
    if (navigator.platform.indexOf("Mac") !== -1) platform = "MacOS";
    if (navigator.platform.indexOf("X11") !== -1) platform = "UNIX";
    if (navigator.platform.indexOf("Linux") !== -1) platform = "LINUX";
    if (navigator.platform.indexOf("iPhone") !== -1) platform = "IOS";
    if (navigator.platform.indexOf("Android") !== -1) platform = "Android";

    this.platforms = JSON.stringify(navigator.platform);
    this.currentPlatform = platform;

    return platform;
  }
}
