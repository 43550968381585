import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'getModuleLink'})
export class GetModuleLinkPipe implements PipeTransform {
  transform(referenceModule: string): string {
    let retVal: string = "";
    if(referenceModule) {
      retVal = "/client/data";
      if(referenceModule == 'TA') {
        retVal += "/assessments";
      } else if(referenceModule == 'PAI') {
        retVal += "/inspections";
      } else if(referenceModule == 'II') {
        retVal += "/incidents";
      } else if(referenceModule == 'KPI') {
        retVal += "/kpis";
      } else if(referenceModule == 'TRM') {
        retVal += "/managements";
      } else if(referenceModule == 'TP') {
        retVal += "/plannings";
      } else if(referenceModule == 'HR') {
        retVal += "/hazards";
      } else if(referenceModule == 'LI') {
        retVal += "/indicators";
      }
      retVal += "/edit";
    }

    return retVal;
  }
}