import { Pipe, PipeTransform } from '@angular/core';
import { Choice } from '../choices/choice';

const DELIMITER = '$';

@Pipe({name: 'isParentSelected'})
export class IsParentSelectedPipe implements PipeTransform {
  transform(choice: Choice, selectedParent: string): boolean {
    let parents: string[] = [];
    if(choice && choice.parents) {
      parents = choice.parents.split(DELIMITER);
    }
    return parents.indexOf(selectedParent) > -1;
  }
}