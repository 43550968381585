import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse} from '@angular/common/http';
import { Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { AppInjectorService } from './app-injector.service';
import { AuthService } from './auth/auth.service';

export class RestErrorInterceptor implements HttpInterceptor {

    private alertController: AlertController;
    private authService: AuthService;
    private router: Router;

    constructor() {
        const injector = AppInjectorService.getInjector();
        this.alertController = injector.get(AlertController);
        this.authService = injector.get(AuthService);
        this.router = injector.get(Router);
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const httpRequest = request.clone({
            headers: request.headers
                .set('Cache-Control', 'no-cache')
                .set('Pragma', 'no-cache')
                .set('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT')
        })

        return next.handle(request)
        .pipe(
            retry(1),
            catchError((error: HttpErrorResponse) => {
                if (error.status !== 401 && error.status !== 403) {
                    let title = 'An error has occurred';
                    let message = error.message;
                    let errorMessage = '';
                    //if (error.error instanceof ErrorEvent) {
                    /*
                    if (error.status == 400) {
                        // client-side error
                        title = 'Client-Side Error'
                    } else {
                        // server-side error
                        title = 'Server-Side Error'
                    }
                    */
                    // 
                    if(error && error.error) {
                        message = error.error.message;
                    } else if(error && error.message) {
                        message = error.message;
                    }
                   
                    errorMessage = `${title} Code: ${error.status}\nMessage: ${message}`;
                this.presentAlert(title, message);
                    // TODO send error email here
                    return throwError(errorMessage);
                } else {
                    if(error.url && error.url.endsWith("/login")) {
                        this.presentAlert('Login Failed', "Invalid username/password");
                    } else {
                        // 
                        this.authService.logout();
                        this.router.navigate(["/login"], {queryParams: {"unauthorized": "true"}});
                    }
                }
            })
        )
    }

    async presentAlert(title: string, message: string) {
        const alert = await this.alertController.create({
          header: title,
          message: message,
          buttons: ['OK'],
          backdropDismiss: false
        });
    
        await alert.present();
      };
    
}