import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';
import { CountElementsPipe } from './count-elements.pipe';
import { FilterElementsPipe } from './filter-elements.pipe';
import { GetCategoryPipe } from './get-category.pipe';
import { GetElementNamePipe } from './get-element-name.pipe';
import { GetModuleLinkPipe } from './get-module-link.pipe';
import { GetModulePipe } from './get-module.pipe';
import { GetNamePipe } from './get-name.pipe';
import { GetPDFIndexesPipe } from './get-pdf-indexes.pipe';
import { GetUserByEmailPipe } from './get-user-by-email.pipe';
import { HasModuleAccessPipe } from './has-module-access';
import { IsParentSelectedPipe } from './is-parent-selected';
import { IsValidImagePipe } from './is-valid-image';
import { LineBreakPipe } from './line-break.pipe';

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
  ],
  exports: [
    GetNamePipe, GetCategoryPipe, GetElementNamePipe, GetPDFIndexesPipe, IsValidImagePipe, GetUserByEmailPipe, FilterElementsPipe, CountElementsPipe, IsParentSelectedPipe, HasModuleAccessPipe, LineBreakPipe, GetModulePipe, GetModuleLinkPipe
  ],
  declarations: [GetNamePipe, GetCategoryPipe, GetElementNamePipe, GetPDFIndexesPipe, IsValidImagePipe, GetUserByEmailPipe, FilterElementsPipe, CountElementsPipe, IsParentSelectedPipe, HasModuleAccessPipe, LineBreakPipe, GetModulePipe, GetModuleLinkPipe]
})
export class ObjectUtilModule {}
