import { Pipe, PipeTransform } from '@angular/core';
import { Category } from '../../services/category-elements/category';

@Pipe({name: 'getElementName'})
export class GetElementNamePipe implements PipeTransform {
  transform(id: string, categories: Category[]): string {
    let retVal: string = undefined;
    if(categories) {
      categories.forEach(category => {
        let element = category.elements.find(element => {
          return element.id == id;
        });

        if(element != undefined) {
          retVal = category.name + " - " + element.name
        }
      });
    }

    return retVal;
  }
}