import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'hasModuleAccess'})
export class HasModuleAccessPipe implements PipeTransform {
  transform(pageId: string, modules: string[]): boolean {
    let retVal = false;

    let id: string = pageId.split("-")[0];
    if(id) {
      if(id == 'CCM') {
        retVal = modules != undefined && modules.indexOf('TRM') > -1 && modules.indexOf('HR') > -1;
      } else {
        retVal = modules != undefined && modules.indexOf(id) > -1;
      }
    }
    
    return retVal;
  }
}