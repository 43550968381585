import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ReportsService } from '../common/services/reports/reports.service';

@Component({
  selector: 'app-download-pdf',
  templateUrl: './download-pdf.component.html',
  styleUrls: ['./download-pdf.component.scss'],
})
export class DownloadPdfComponent implements OnInit {

  link: string = ''
  constructor(
    private route: ActivatedRoute,
    private reportsService: ReportsService
  ) {
    route.queryParams.subscribe(res => {
      this.link = res.id
      this.download()
    })

  }

  ngOnInit() { }

  download() {
    this.reportsService.downloadReportPdfForEmail(this.link)
  }
}
