import { Injectable } from '@angular/core';
import { OnInit } from '@angular/core';
import {Storage} from '@ionic/storage';

@Injectable({
    providedIn: 'root'
})
export class SessionService implements OnInit {

    constructor(private storage: Storage) { }

    ngOnInit() { }
    
    async set(key:string, value: string) {
        await this.storage.set(key, value);
    }

    async get(key:string):Promise<any> {
        return this.storage.get(key);
    }

    clear() {
        this.storage.clear();
    }
}