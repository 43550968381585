import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { defineCustomElements } from '@ionic/pwa-elements/loader';

import { AppModule } from './app/app.module';
import { AppInjectorService } from './app/common/services/app-injector.service';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

defineCustomElements(window);

platformBrowserDynamic().bootstrapModule(AppModule)
  .then((moduleRef) => {
    AppInjectorService.setInjector(moduleRef.injector);
  })
  .catch(err => {
    
  });
