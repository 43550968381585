import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'filterElements' })
export class FilterElementsPipe implements PipeTransform {
  transform(element_compliance: {}, field: string): any {
    let retVal = {};
    Object.keys(element_compliance).forEach(key => {
      if (element_compliance[key][field]) {
        retVal[key] = element_compliance[key];
      }
    });
    return retVal;
  }
}